import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { BreadcrumbLayer } from '../../types'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { useTranslation } from 'react-i18next'

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  // event.preventDefault()
  trackEvent({
    category: 'Breadcrumb',
    action: 'Click',
    label: '' + event.currentTarget.dataset.tag,
  })
}

export interface BreadcrumbLayerElementProps {
  layer: BreadcrumbLayer
  i: number
}

export default function BreadcrumbLayerElement(
  props: BreadcrumbLayerElementProps,
) {
  const { t } = useTranslation('translation')

  const layer = props.layer
  const i = props.i

  const layerName = ('' + t(layer.name)).toUpperCase()

  if (layer.url === undefined) {
    return <Typography color="textPrimary">{layerName}</Typography>
  } else {
    return (
      <Link
        color="inherit"
        data-tag={i + '-' + layer.name}
        href={layer.url}
        key={'breadcrumb_' + i}
        onClick={handleClick}
      >
        {layerName}
      </Link>
    )
  }
}
